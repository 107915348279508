<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle=" 委託契約書一括CSV取込 "/>
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <div class="c-alart">
          <div class="icon"></div>
          <p>契約データの一括登録中に、関連事業者の情報を編集しないでください。実施前後には必ず社内への周知を行ってください。</p>
        </div>
      </div>
      <div class="l-container">
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">委託契約書一括CSV取込</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>委託契約書CSVデータ</label>
            </div>
            <template v-if="!selectedCsvFile">
              <div class="c-inputwrap__items">
                <input id="csv" accept=".csv" type="file" @change="(e) => onSelectCsvFile(e)" />
               <label for="csv" class="c-btn secondary upload sm">CSVファイル選択</label>
              </div>
            </template>
            <template v-else>
              <a class="c-entrustText__text">
                {{ selectedCsvFile.name }}
              </a>
              <div :class="{ disabled: processing }" @click="deleteSelectedCsv()" class="c-btn secondary delete sm">
                削除
              </div>
            </template>
          </div>

          <div class="c-errMsg__list" v-if="bytesError">
            <p>10MB以内のファイルを選択してください</p>
          </div>
          <div class="c-errMsg__list" v-if="csvFilenameError">
            <p>CSVファイル名は拡張子含めて255文字以内に変更してください。</p>
          </div>
          <div class="c-errMsg__list" v-if="errMsg">
            <p>{{ errMsg }}</p>
          </div>

        </div>
      </div>
      <div class="l-container">
        <div class="l-contents l-box" v-if="isCsvError">
          <div class="l-box title-wrap">
            <h3 class="c-lead c-errMsg">アップロードされたCSVに不備があります</h3>
          </div>
          <div>
            <ul class="c-errMsg__list">
              <li class="c-errMsg__item" v-for="(error, index) in csvRowError"  :key="`error-${index}`">
                <p class="errorList">{{ error.errorMessage }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="l-flex cols l-item" v-if="!isCsvError && registerCsv">
          <div class="u-txt-bold">レコード数 {{registerCsv.length}}行</div>
        </div>

      </div>

      <div class="l-container l-btns">
        <div class="left">
          <a class="c-btn secondary small back" @click="backHandler">戻る</a>
        </div>
        <div class="center">
          <div class="c-btn primary mid register" :class="{ disabled: processing || registerCsv == null || this.csvRowError.length > 0 }" @click="isDisplayConfirmRegisterModal = true" >登録</div>
        </div>
      </div>

    </main>

        <!-- アップロード確認のモーダル -->
    <div v-if="isDisplayConfirmRegisterModal" class="c-modal sm">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          委託契約書を{{ registerCsv.length }}件予約登録します<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isDisplayConfirmRegisterModal = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" :class="{ disabled: processing }" @click="registerHandler">登録</div>
        </div>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import {API_URL} from "@/const";

export default {
  name: "entrust-upload",
  props: {
    onClickUploadButton: {
      type: Function,
    },
  },

  data() {
    return {
      menu: this.$store.getters["menu/get"],
      user: this.$store.getters["userInfo/get"],
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      isCollectProxy: this.$store.getters["userInfo/isCollectProxy"],
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      showValidateInfo: false,
      selectedCsvFile: null,
      csvUploadProcessing: false,
      isDisplayConfirmRegisterModal: false,
      processing: false,
      registerCsv: null,
      count: 0,
      bytesError: false,
      isCsvError: false,
      csvRowError: [],
      errMsg: null,
      csvFilenameError: null,
    };
  },

  methods: {

    //csvアップロードボタン押下時
    onSelectCsvFile(e) {
      if (!e.target.files[0].type.match("text/csv")) {
        alert("CSVファイルを選択してください");
        return;
      }
      this.selectedCsvFile = e.target.files[0];
      if (this.selectedCsvFile.size >= 10000000) {
        // 10MBを超えたら、エラーとする
        this.bytesError = true;
      } else if (this.selectedCsvFile.name.size > 255) {
        // ファイル名の長さが255文字を超えたら、エラーとする
        this.csvFilenameError = true
      } else {
        const params = new FormData();
        params.append("file", this.selectedCsvFile);
        this.processing = true;
        axios
          .post(API_URL.ENTRUST.CSV_VALIDATE, params, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            this.processing = false;
            this.registerCsv = res.data.results;
            this.isCsvError = this.registerCsv.filter((row) => row.csvLumpErrorList && row.csvLumpErrorList.length > 0).length > 0;
            this.csvRowError = this.registerCsv.map ((row) => row.csvLumpErrorList).flat();
          })
          .catch((err) => {
            this.processing = false;
            this.errMsg = err.response && err.response.data ? err.response.data.message : err.message
            console.log(err);
          });
      }
    },

     //アップロードしたファイル削除
    deleteSelectedCsv() {
      this.selectedCsvFile = null;
      this.bytesError = false;
      this.registerCsv = null;
      this.isCsvError = false;
      this.csvRowError = [];
      this.errMsg = null;
      this.csvFilenameError = null;
    },

    //アップロードしたファイルの登録
    registerHandler(){
      this.processing = true;
      const params = new FormData();
      params.append("file", this.selectedCsvFile);
      params.append("fileName", this.selectedCsvFile.name);
      params.append("rowNumber", this.registerCsv.length);
      return axios
        .post(API_URL.ENTRUST.CSV_IMPORT, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          // 委託契約一括確認画面へ繊維
          this.processing = false; // 2重submit解除
          this.$router.push("/entrust/confirm");
        })
        .catch((err) => {
          this.processing = false;
          console.log(err);
        });
    },

    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },
  },

  
}
</script>

<style scoped>

.l-btns{
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
  background-color: rgba(255,255,255,0.75);
}
.l-btns .center{
  position: fixed;
  left: 50%;
}
.l-btns > * + *{
  margin-left: 0;
}
.c-errMsg__list {
  margin-left: 0;
} 
.c-errMsg{
  color: #ff0000;
  &::before{
    content: none;
  }
}
.isError{
  background-color: #FFE0E0;
  position: relative;
}
.isErrorIcon::before{
  content: "";
  background-image: url(/v_resources/assets/img/icon/info_red.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  position: absolute;
  top: 2px;
  left: 2px;
}
.l-flex{
  gap: 20px;
}

.c-btn.delete{
  margin-left: 10px;
}

.errorList{
  display: inline-block;
  margin-right: 10px;
}

.errorList:first-child{
  margin-left: 10px;
}

.errorList:not(:last-child)::after{
  content: "、";
  display: inline-block;
  position: absolute;
  width: 1rem;
}

.u-text-center{
  text-align: center;
}

</style>